import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style2 special">
      <div className="inner">
        <header className="major">
          <h2>
          Unleashing the potential of your business 
            <br />
             with tailor-made solutions.
          </h2>
          <p>
            We have deep expertise in digital assets, blockchain, data mining and cybersecurity.
            <br />
            We handle the complexity and let you the efficiency. 
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-chart-bar major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-handshake major style2">
              <span className="label">handshake</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Project management
           
          </h2>
          <p>
            We are agile, lean and flexible in our approach adapted to every client’s unique demand.
            <br />
            We work hand in hand with our clients, defining needs, then we organized, 
            planned, streamlined and completed most efficiently within time and budget.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
           Cross-functionnal team
          </h2>
          <p>
          From business analyst to data scientists, graphic designers and developers, we gather a team of experts to deliver state-of-art technologies that work.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Implementation
            <br />
          
          </h2>
          <p>
          Never get lost on current development, You will always be up to date thanks to the dedicated product owner who will track changes. 
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2> Our clientiele has high requirements and quality standards </h2>
          <p>
            We love building products and services that make life easier.
            <br />
            We have a broad range of clients from banks, family office, funds, law firms and startups. 
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-paper-plane">
            <h3>Blockchain Training</h3>
            <p>
            From Bitcoin to ethereum, we provide hands-on trainings and seminars featured with live blockchain projects. 
            </p>
          </li>
          <li className="icon solid fa-laptop">
            <h3>cybersecurity</h3>
            <p>
            it is critical that business leaders from organizations of all sizes analyze 
            their security posture and processes.
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>DevOps</h3>
            <p>
              We remove cumbersome software and use proven technologies to simplify your infrastructure and save costs, drastically. 
            </p>
          </li>
          <li className="icon solid fa-headphones-alt">
            <h3>Data Investigation</h3>
            <p>
              Save time with customer check, fraud detection and data monitoring. Whatever your inquiry is, we ensure you that you have the right tools.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Cryptocurrencies</h3>
            <p>
              Find the most efficient solution to integrate cryptocurrencies in your existing infrastructure, hassle-free.
            </p>
          </li>
          <li className="icon fa-flag">
            <h3>Tokenomics</h3>
            <p>
            No matter your constraints are, we design, review and build digital assets on existing blockchains. 
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Engage with US</h2>
          <p>
          Our teams are ready to enable greatness in your organization, read more to explore all of our delivery capabilities.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/#" className="button fit primary">
              Activate
            </a>
          </li>
          <li>
          
          <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
          
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
